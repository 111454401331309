(function($, undefined) {
	
	'use strict';
	var contactFormBtn;
	
	
	var sendContactFormEmailClass = function() {
		
		var t = $(this);
		
		var validate = t.data('validate');
		
		var count = t.data('count');
		
		var form = $('.contactForm-Container');

		var subject = $('.contactForm-subject', form).val();
		var email = $('.contactForm-email', form).val();
		var targetEmail = ($('.targetEmail', form)?$('.targetEmail', form).val():"");
		var content = null;
		try {
			content = tinyMCE.get('contactForm-content', form).getContent();
		} catch (exception) { 
			content = $('.contactForm-content', form).val();
		}

		var appendField="";
		if($('.fieldName1')!==null) {
			var total = 9;
			if (count) {
				total = count;
			}
			for (var i = 1; i < total; i++) {
				appendField+="&fieldName"+i+"="+
					encodeURIComponent($('.fieldName'+i, form).val());
				if($('.field'+i, form).attr('type') == "checkbox") {
					if ($('.field'+i, form).checked) {
						appendField+="&field"+i+"=checked.";
					} else {
						appendField+="&field"+i+"=not checked.";
					}
				} else {
				  appendField+="&field"+i+"="+
					  encodeURIComponent($('.field'+i, form).val());
				}
			}
			appendField+="&count="+total+"&targetEmail="+targetEmail;
		}
		var captcha = $('.contactForm-captcha', form);
		if (captcha) {
			appendField += '&captcha='+encodeURIComponent(captcha.val());
		}
		
		var validForm = true;    
		var inputs = $('input', form);
		inputs.each(function () {
			var tInput = $(this);
	
			if ((tInput.attr('type')==="text" || tInput.attr('type')==="email" || tInput.attr('type')==="tel") && tInput.val()==="") {
				
				tInput.css('background-color', '#dedede');
				validForm = false;
			}
			
			else {
				tInput.css('background-color', '');
			}
			
		});
		var textareas = $('textarea', form);
		textareas.each(function () {
			var tTextareas = $(this);
			
			if (tTextareas.val()==="") {
				tTextareas.css('background-color', '#dedede');
				validForm = false;
			}
			
			else {
				tTextareas.css('background-color', '');
			}
		});
		
		if (!validForm && validate) {
			//popErrorDisplayOnSite('error.message.emptyForm');
			$('.contact-form-form-error').css('display', 'block');
		} else {
			$('.contact-form-form-error').css('display', 'none');
			ajax(actionPath + "contactUs?subject="+encodeURIComponent(subject)+
				"&email="+encodeURIComponent(email)+
				"&content="+encodeURIComponent(content)+
				appendField, sendContactFormEmailConfirmClass(form));
		}
	};
	
	var popErrorDisplayOnSite = function (key) {
		if (key.indexOf('errorMessageAjax') != -1) {
			document.getElementById('errorMessageAjax').style.display = "block";
			document.getElementById('errorMessageDivOriginal').style.display = "none";
		} else {
			document.getElementById('errorMessageAjax').style.display = "none";
			document.getElementById('errorMessageDivOriginal').style.display = "block";
			ajax('/action/getLabel?key='+key, popErrorCompleteDisplayOnSite);
		}
	};
	
	var sendContactFormEmailConfirmClass = function (form) {
		var ctn = $('.contactForm-Container');
		var msg = $('.contactForm-sent');
		
		ctn.slideUp(800, function() {
			msg.slideDown(800);
		});
	};
	
	var popErrorCompleteDisplayOnSite = function(response) {
		footerErrorMessage.slideDown(800);
	};
	
	
	var init = function (page) {
		var currPage = $(page);
		
		contactFormBtn = $('.js-contact-form-send', currPage);
		
		contactFormBtn.click(sendContactFormEmailClass);
	};
	
	
	var sendContactForm = Fw.modules.export('sendContactForm', {
		pageLoad: init
	});
	

	
})(jQuery);
