(function($, undefined) {
	
	'use strict';
    
    var langBtn;	
    
    var redirectPage = function() {
        var lang = window.navigator.language;
        var myLanguageBrowser = lang.indexOf('-') != -1 ? lang.split('-')[0] : lang;
        var href = langBtn.attr('href');
        
        // console.log("document.documentElement.lang : " + document.documentElement.lang);
        // console.log("localStorage langWasSet : " + localStorage.getItem('langWasSet'));
        // console.log("myLanguageBrowser : " + myLanguageBrowser);
        // console.log("localStorage langChosen : " + localStorage.getItem('langChosen'));
        
        if ((localStorage.getItem('langChosen') !== 'en') && (localStorage.getItem('langChosen') !== 'fr')) {
            localStorage.setItem('langChosen', myLanguageBrowser);
        }
        
        if(localStorage.getItem('langWasSet') !== 'true') {
            localStorage.setItem('langWasSet', 'true');
            localStorage.setItem('langChosen', myLanguageBrowser);
            
            if(myLanguageBrowser != document.documentElement.lang){
                window.location.href = href;
            } 
        } else if(localStorage.getItem('langChosen') !== document.documentElement.lang) {
            window.location.href = href;
        }      
    };

	var init = function (page) {
		var currPage = $(page);
		
		/* si on est pas en admin */
        if (!$('body').hasClass('is-logged-in')){
	        langBtn = $('.site-lang-btn');
			redirectPage();
	        langBtn.on('click', function() {
	           localStorage.setItem('langChosen', $(this).text().toLowerCase()); 
	        });
        }

		$(".home-slider", currPage).slick({
			arrows: false,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			centerMode: true,
			centerPadding: '-100px',
			speed: 800,
			cssEase: 'linear',
		});
	};

	//Toujours mettre le ID de la clef de la page
	//La clef est le /page/@name de la langue par défaut de la page
	
	/*var home = Fw.page.export('home', {
		init: init
	});*/
	
    var home = Fw.modules.export('home', {
        pageLoad: init
    });
	
	var pageEnter = function (page) {
	};

    
		
})(jQuery);
