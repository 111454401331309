(function($, undefined) {
	
	'use strict';
	
	var addExternalLinkTarget = function () {
		$.expr[':'].external = function(obj){
			return !obj.href.match(/^mailto\:/) &&
			(obj.hostname != location.hostname) &&
			!obj.href.match(/^javascript\:/) &&
			!obj.href.match(/^tel\:/) &&
			!obj.href.match(/^$/) ||
			obj.href.match(/\.pdf$/);
		};
		
		$('a:external').attr('target', '_blank');
	};
	
	
	var init = function (page) {
		var currPage = $(page);
		addExternalLinkTarget.apply(currPage);
	};
	
	
	var externalLinkTarget = Fw.modules.export('externalLinkTarget', {
		pageLoad: init
	});
	
})(jQuery);
