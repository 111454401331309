(function($, undefined) {
	
	'use strict';
	
	
	
	var init = function (page) {
		var currPage = $(page);
	};
	
	var testCom = Fw.component.export('testCom', {
		init: init
	});
	
})(jQuery);
