(function($, undefined) {
	
	'use strict';
	var mapElement;
	var map;

	var init = function (page) {
		var currPage = $(page);
		mapElement = $('#contact-map', currPage);
		
		var mapOptions = {

		    zoom: 14,
		    scrollwheel: false,
		    navigationControl: false,
		    mapTypeControl: false,
		    scaleControl: false,

		    // Coordonnées géographiques
		    center: new google.maps.LatLng(45.6748291, -73.8606679), // Luce Lumen, Blainville, Québec

		    // Style de la map
		    styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road","elementType":"geometry","stylers":[{"hue":"#ff0000"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"},{"hue":"#ff0000"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"on"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#ed1b23"},{"visibility":"on"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#000000"}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"visibility":"off"}]}]
		};

		map = new google.maps.Map(mapElement[0], mapOptions);

		// Ajout d'un marqueur
		var marker = new google.maps.Marker({
		    position: new google.maps.LatLng(45.6748291, -73.8606679), 
		    map: map,
		    icon: '/static/assets/img/map-icon.png',
		    title: 'Luce Lumen'
		});
		
	};
	
	var contact = Fw.page.export('contact', {
		init: init
	});

})(jQuery);