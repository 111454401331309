(function($, undefined) {
	
	'use strict';
	var isLoggedIn = false;
	var isMobile = false;
	var win = $(window);
	var winWidth;
	var winHeight;
	
	var headerEl;
	var navItems;
	
	var toggleMenuBtn;
	
	
	var toggleScroll = function () {
		var diff;
		
		if ($('body').hasClass('no-scroll')) {
			$('body').removeClass('no-scroll');
			diff = 0;
		} else {
			var w1 = win.width();
			$('body').addClass('no-scroll');
			var w2 = win.width();
			diff = w2 - w1;
		}
		
		changeScrollDiff(diff);
	};
	
	var changeScrollDiff = function (diff) {
		$('.js-scroll-padding').css('padding-right', diff + 'px');
		$('.js-scroll-right').css('right', diff + 'px');
	};
	
	var onClickMenuToggle = function () {
		if (!!headerEl.hasClass('menu-open')) {
			var transitionEnd = 'transitionend webkitTransitionEnd oTransitionEnd otransitionend';
			headerEl.removeClass('menu-open');
			
			headerEl.on(transitionEnd, function(e) {
				$('body').removeClass('no-scroll');
				changeScrollDiff(0);
				$(this).off(e);
			});
		} else {
			var target = 0;
			
			if ($('.site-header').css('scroll-y') !== 'fixed' && $('.site-header').offset().top > 0) {
				target = $('.site-header').offset().top;
			}
			
			if (win.scrollTop() <= $('.site-header').offset().top) {
				$('html').animate({scrollTop: target}, 300);
				$('body').animate({scrollTop: target}, 300, function () {
					openMenu();
				});
			} else {
				openMenu();
			}
		}
	};
	
	var openMenu = function () {
		headerEl.addClass('menu-open');
		toggleScroll();
	};
	
	var closeMenu = function () {
		if (headerEl.hasClass('menu-open')) {
			headerEl.removeClass('menu-open');
			toggleScroll();
		}
	};
	
	var updateNavSelected = function (page) {
		navItems.removeClass('selected');
		var handleObj = $('.main-content ' + page);
		var handle = handleObj.attr('data-page-nav');
		
		navItems.each(function () {
			var t = $(this);
			
			if (t.data('pageNav') === handle) {
				t.addClass('selected');
			}
		});
	};
	
	var updateLangBtn = function () {
		var data = Fw.data;
		var newBtn = $('.site-lang-btn', data);
		var newText = newBtn.html();
		var newUrl = newBtn.attr('href');
		
		var currBtn = $('.site-lang-btn', headerEl);
		currBtn.attr('href', newUrl);
		currBtn.html(newText);
	};
	
	var pageEnter = function (page) {
		updateNavSelected(page);
	};
	
	var pageStartChange = function (page) {
		updateLangBtn();
	};
	
	var init = function (page) {
		var currPage = $(page);
		
		winWidth = win.width();
		winHeight = win.height();
		$('body').removeClass('no-scroll');
		
		headerEl = $('.site-header', page);
		navItems = $('.nav-item-link', headerEl);
		
		toggleMenuBtn = $('.site-header-menu-toggle-btn', headerEl);
		
		
		if($('body').hasClass('is-logged-in')) {
			isLoggedIn = true;
		}
		
		toggleMenuBtn.on('click', onClickMenuToggle);
		navItems.on('click', closeMenu);
		
		
		win.resize(function() {
			if (win.width() != winWidth) {
				winHeight = win.height();
				winWidth = win.width();
			}
		});
	};
	
	var header = Fw.modules.export('header', {
		init: init,
		pageEnter: pageEnter,
        pageStartChange: pageStartChange
	});
	
	
	/* ***** RESPONSIVE MENU BUTTON ***** */
	
	$(".header-hamburger-ctn").click(function(){
	    $('.header-nav').stop().slideToggle(600); 
	});
	
	
	/* ***** WINDOW RESIZE MENU FUNCTION ***** */
	
	$(window).resize(function () {
		if ( $(window).width() > 1007 ) {
			
			$('.header-nav').removeAttr('style');
			$('.header-dropdown-content a').removeAttr('style');
		}
	});
	
	$('.header-nav a').not('.products-dropdown-list').click(function () {
		if ( $(window).width() < 1007 ) {
			
			$('.header-nav').slideUp(600);	
		}
	});
	
	
	/* ***** MENU SECTION HIGHLIGHT ***** */
	
	$('.site-header a').click(function() {
	    $('.site-header a.section-selected').removeClass('section-selected');
	    $(this).closest('a').addClass('section-selected');
	});
	
	$('.header-dropdown-content a').click(function() {
	    $('.site-header a.section-selected').removeClass('section-selected');
	    $('.products-dropdown-list').addClass('section-selected');
	});
			
	
	/* ***** RESPONIVE DROP-DOWN ***** */
		
	$('.products-dropdown-list').click(function () {
		if ( $(window).width() < 1007 ) {
			
			$('.header-dropdown-content a').stop().slideToggle(600); 	
		}
	});
 
})(jQuery);






